import { NormalButton, DontCareButton } from "../Components/Buttons"
import { lengthPick } from "../Services/API"
import React, { Component } from 'react';


class Length extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }


    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>A show that's...</h2>
                <NormalButton funcPick={lengthPick} text={["Just a short one", <span key="14" className="btn-lowertext">(Mini-series)</span>]} data="mini" link="/genre"/>
                <NormalButton funcPick={lengthPick} text={["A one season wonder", <span key="14" className="btn-lowertext">(1 season)</span>]} data="0 AND 1" link="/genre"/>
                <NormalButton funcPick={lengthPick} text={["A few seasons", <span key="15" className="btn-lowertext">(2-4 seasons)</span>]} data="2 AND 4" link="/genre"/>
                <NormalButton funcPick={lengthPick} text={["Gonna take a while", <span key="16" className="btn-lowertext">(4 seasons+)</span>]} data="4 AND 50" link="/genre"/>
                <DontCareButton funcPick={lengthPick} text="Time is but a concept" data="0 AND 10000" link="/genre"/>
            </div>
        );
    };
};

export default Length;