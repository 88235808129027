import { NormalButton, DontCareButton } from "../Components/Buttons"
import { streamPick } from "../Services/API"
import React, { Component } from 'react';

class Stream extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
            <h2>I'm watching with...</h2>
                <NormalButton funcPick={streamPick} text="Netflix" data="%Netflix%" link="/length"/>
                <NormalButton funcPick={streamPick} text="Stan" data="%Stan%" link="/length"/>
                <NormalButton funcPick={streamPick} text="Amazon Prime" data="%Amazon%" link="/length"/>
                <NormalButton funcPick={streamPick} text="Disney+" data="%Disney Plus%" link="/length"/>
                <NormalButton funcPick={streamPick} text="Paramount Plus" data="%Paramount Plus%" link="/length"/>
                <DontCareButton funcPick={streamPick} text="Choose them all!" data="any" link="/length"/>
            </div>
        );
    };
};

export default Stream;