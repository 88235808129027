export function Dates(props) {

    var today, date, pastDate, dateToday

    today = new Date()
    //Todays Date
    dateToday = today.getFullYear() + "-0" + (1+today.getMonth()) + '-0' + today.getDate();
    
    switch(props.option) {
        //between start of this year to now
        case 1:
            pastDate = new Date(today);
            pastDate.setDate(pastDate.getDate())

            date = "'" + pastDate.getFullYear() + '-01-01\' AND \'' + dateToday + "'";
            return date;
        //between today and last 3 years
        case 2:
            pastDate = new Date(today);
            pastDate.setDate(pastDate.getDate() - 1095)

            date = "'" + pastDate.getFullYear() + '-01-01\' AND \'' + dateToday + "'";
            return date;
        //between today AND last 10 years            
        case 3:
            pastDate = new Date(today);
            pastDate.setDate(pastDate.getDate() - 3650)

            date = "'" + pastDate.getFullYear() + '-01-01\' AND \'' + dateToday + "'";
            return date; 
        //between today and last 25 years
        case 4:
            pastDate = new Date(today);
            pastDate.setDate(pastDate.getDate() - 9125)

            date = "'" + pastDate.getFullYear() + '-01-01\' AND \'' + dateToday + "'";
            return date; 
        //between today and last 100 years
        case 5:
            pastDate = new Date(today);
            pastDate.setDate(pastDate.getDate() - 36500)

            date = "'" + pastDate.getFullYear() + '-01-01\' AND \'' + dateToday + "'";
            return date; 
        default:
    }
}

export default Dates