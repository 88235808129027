import { NormalButton, DontCareButton } from "../Components/Buttons"
import { votecountPick } from "../Services/API"
import React, { Component } from 'react';


class Votecount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    
    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>How popular...?</h2>
                <NormalButton funcPick={votecountPick} text={["Everyone knows about it", <span key="17" className="btn-lowertext">(Over 50,000 votes)</span>]} data="'50000' AND '1000000000'" link="/pick"/>
                <NormalButton funcPick={votecountPick} text={["Fairly well known and above", <span key="18" className="btn-lowertext">(Over 10,000 votes)</span>]} data="'10000' AND '1000000000'" link="/pick"/>
                <DontCareButton funcPick={votecountPick} text="I really don't care" data="'0' AND '10000000000'" link="/pick"/>
            </div>
        );
    };
};

export default Votecount;