import { NormalButtonSmall, DontCareButton } from "../Components/Buttons"
import { timePick } from "../Services/API"
import React, { Component } from 'react';

class Time extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }


    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>Specifically...</h2>
                <div className="Specific">
                    <div className="Specific-left">
                        <NormalButtonSmall funcPick={timePick} text="2020+" data="'2020-01-01' AND '2099-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="2015 to 2020" data="'2015-01-01' AND '2020-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="2010 to 2015" data="'2010-01-01' AND '2015-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="2005 to 2010" data="'2005-01-01' AND '2010-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="2000 to 2005" data="'2000-01-01' AND '2005-01-01'" link="/rating"/>

                    </div>
                    <div className="Specific-right">
                        <NormalButtonSmall funcPick={timePick} text="1990 to 2000" data="'1990-01-01' AND '2000-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="1980 to 1990" data="'1980-01-01' AND '1990-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="1970 to 1980" data="'1970-01-01' AND '1980-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="1960 to 1970" data="'1960-01-01' AND '1970-01-01'" link="/rating"/>
                        <NormalButtonSmall funcPick={timePick} text="1900 to 1960" data="'1900-01-01' AND '1960-01-01'" link="/rating"/>
                    </div>
                </div>
                <DontCareButton funcPick={timePick} text="I still don't care" data="'1900-01-01' AND '2099-01-01'" link="/rating"/>

            </div>
        );
    };
};

export default Time;