import { NormalButton, DontCareButton, SpecificButton } from "../Components/Buttons"
import { genrePick } from "../Services/API"
import { RandomGenre } from "../Services/RandomGenre"
import React, { Component } from 'react';

class Genre extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
            <h2>Genre wise...</h2>
                {/* <NormalButton funcPick={genrePick} text={["Fast-paced", <p className="test">(Action, Adventure, Crime, Drama, War)</p>]} data={RandomGenre({option: 1})} link="/collection"/> */}
                <NormalButton funcPick={genrePick} text={["Dramatic", <span key="1" className="btn-lowertext">(Action, Adventure, Drama)</span>]} data={RandomGenre({option: 1})} link="/time"/>
                <NormalButton funcPick={genrePick} text={["Gentle",<span key="3" className="btn-lowertext">(Comedy, Family, Romance)</span>]} data={RandomGenre({option: 2})} link="/time"/>
                <NormalButton funcPick={genrePick} text={["Curious", <span key="4" className="btn-lowertext">(History, Mystery)</span>]} data={RandomGenre({option: 3})} link="/time"/>
                <NormalButton funcPick={genrePick} text={["Out of this world", <span key="5" className="btn-lowertext">(Fantasy, Science-Fiction)</span>]} data={RandomGenre({option: 5})} link="/time"/>
                <NormalButton funcPick={genrePick} text={["Interesting", <span key="4" className="btn-lowertext">(Documentary)</span>]} data="genres->'$[0].name' = 'Documentary'" link="/time"/>
                <NormalButton funcPick={genrePick} text={["Lots of speaking", <span key="2" className="btn-lowertext">(News, Reality, Soap, Talk)</span>]} data={RandomGenre({option: 4})}  link="/time"/>
                <SpecificButton text="Let me pick specifically" link="/genreall"/>
                <DontCareButton funcPick={genrePick} text="I don't care" data={RandomGenre({ option: 6 })} link="/time"/>
            </div>
        );
    };
};

export default Genre;