import React, { Component } from 'react';

class Footer extends Component {
    render () {
        return (
            <footer className="footer">     
                tvdecider.com · The Fun TV Recommendation Engine · 2022
            </footer>
        );
    };
};

export default Footer;