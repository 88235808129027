import { NormalButton, DontCareButton } from "../Components/Buttons"
import { ratingPick } from "../Services/API"
import React, { Component } from 'react';


class Rating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    
    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>And is...</h2>
                <NormalButton funcPick={ratingPick} text={["Highly rated", <span key="12" className="btn-lowertext">(Over 7/10 rated)</span>]} data="'7' AND '10'" link="/votecount"/>
                <NormalButton funcPick={ratingPick} text={["At least average", <span key="13" className="btn-lowertext">(Over 5/10 rated)</span>]} data="'5' AND '10'" link="/votecount"/>
                <DontCareButton funcPick={ratingPick} text="I don't mind" data="'0' AND '10'" link="/pick"/>
            </div>
        );
    };
};

export default Rating;