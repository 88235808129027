import React from 'react';

//CSS
import './App.css';
// import './hover.css'
import 'bootstrap/dist/css/bootstrap.min.css';

//Router
import { Switch, Route, MemoryRouter  } from 'react-router-dom/cjs/react-router-dom.min';

//Button choice
import Length from "./Pages/1-Length";
import Genre from "./Pages/2-Genre";
import Stream from "./Pages/0-Stream";
import GenreAll from "./Pages/2.5-GenreAll";
import Time from "./Pages/3-Time";
import TimeAll from "./Pages/3.5-TimeAll";
import Votecount from "./Pages/5-Votecount";
import Rating from "./Pages/4-Rating";

import Pick from "./Pages/Pick";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import Home from "./Pages/Home";

import NavbarMD from "./Components/Navbar";
import Footer from "./Components/Footer";


class App extends React.Component {

  render () {
    return (

      <MemoryRouter>
        <NavbarMD />
          <div className="App">
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <Route path="/about" component={About}></Route>
                <Route path="/contactus" component={ContactUs}></Route>

                <Route path="/stream" component={Stream}></Route>
                <Route path="/genre" component={Genre}></Route>
                <Route path="/genreall" component={GenreAll}></Route>
                <Route path="/time" component={Time}></Route>
                <Route path="/timeall" component={TimeAll}></Route>
                <Route path="/votecount" component={Votecount}></Route>
                <Route path="/rating" component={Rating}></Route>
                <Route path="/length" component={Length}></Route>
                <Route path="/pick" component={Pick}></Route>

              </Switch>
          </div>
        <Footer />
      </MemoryRouter>
      
    )
  }
}

export default App;