import React, { Component } from 'react';

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }
    render () {
        return (
            <div className="Pick-border">

            <div className="About">
                <div className={this.state.isVisible ? "isVisibleHomeTitle": "isNotVisibleHomeTitle"}>
                    <h4 className="About-Title">About</h4>
                </div>

                <div className={this.state.isVisible ? "isVisibleHomePara": "isNotVisibleHomePara"}>
                    <p className="About-Para">Various streaming platforms and other services offer recommendations, 
                    algorithms based on our search history, watch history or history in general. 
                    What they fail to include is a pain-free, fast, intuitive decider, 
                    where depending on how someone is feeling in the moment will impact their recommendation. 
                    <br></br><br></br>Furthermore, this project acted as an opportunity to learn how to create a website from scratch on all OSI layers – physical to application.</p>
                </div>
            </div>
            </div>
        );
    };
};

export default About;