import Axios from 'axios';

//post to nodejs
export function streamPick(stream){
  Axios.post("/streamPick", {
    data: stream,
  })
}

export function genrePick(genre){
    Axios.post("/genrePick", {
      data: genre,
    })
}

export function timePick(time){
  Axios.post("/timePick", {
    data: time,
  })
}

export function ratingPick(rating){
  Axios.post("/ratingPick", {
    data: rating,
  })
}

export function votecountPick(votecount){
  Axios.post("/votecountPick", {
    data: votecount,
  })
}

export function lengthPick(length){
  Axios.post("/lengthPick", {
    data: length,
  })
}


export default genrePick;