import React, { Component } from 'react';


class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }
    render () {
        return (
            <div className="Pick-border">
                <div className="About">
                    <div className={this.state.isVisible ? "isVisibleHomeTitle": "isNotVisibleHomeTitle"}>
                        <h4 className="About-Title">Contact Us</h4>
                    </div>

                    <div className={this.state.isVisible ? "isVisibleHomePara": "isNotVisibleHomePara"}>
                        <p className="About-Para">Feel free to reach out to admin@moviedecider.com</p>
                    </div>
                </div>
            </div>
        );
    };
};

export default ContactUs;