import { NormalButton, DontCareButton, SpecificButton } from "../Components/Buttons"
import { timePick } from "../Services/API"
import { Dates } from "../Services/Dates"
import React, { Component } from 'react';

class TimeAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }
    fadeIn() {
        setTimeout( () => {
            this.setState({isVisible : true});
        }, 0)        
    }
    componentDidMount() {
        this.fadeIn();
    }

    render () {
        return (
            <div className={this.state.isVisible ? "isVisible": "isNotVisible"}>
                <h2>And from...</h2>
                <NormalButton funcPick={timePick} text="This year" data={Dates({ option: 1 })} link="/rating"/>
                <NormalButton funcPick={timePick} text="Last few years" data={Dates({ option: 2 })} link="/rating"/>
                <NormalButton funcPick={timePick} text="Last 10 years" data={Dates({ option: 3 })} link="/rating"/>
                <NormalButton funcPick={timePick} text="Last 25 years" data={Dates({ option: 4 })} link="/rating"/>
                <NormalButton funcPick={timePick} text="Last 100 years" data={Dates({ option: 5 })} link="/rating"/>
                <SpecificButton text="Let me pick specifically" link="/timeall"/>
                <DontCareButton funcPick={timePick} text="I still don't care" data="'1900-01-01' AND '2099-01-01'" link="/rating"/>
            </div>
        );
    };
};

export default TimeAll;